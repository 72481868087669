jQuery(document).ready(function($){

	$('.acc__content').hide();
	$('#accText-0').show();

	$('.acc__sub').hide();

	$('.acc__item a').click(function(e){
		
		e.preventDefault();
		
		if ( $(this).attr('data-open') == 'true' ){
			
			$(this).next('ul').slideUp('slow');
			$(this).attr('data-open', 'false');

		} else  {
			$(this).next('ul').slideDown('slow');
			$(this).attr('data-open', 'true');
		}

		var id = $(this).attr('href');

		console.log(id);

		if (id){
			$('.acc__content').hide();
			$(id).fadeIn();
		}

	});

	$('a[href="http://flexibilitypv.com"]').attr('href', '/');


});